import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import artunity1 from '../images/artunity/artunity-mockup-1.jpg';
import artunity2 from '../images/artunity/artunity-mockup-2.jpg';
import artunity3 from '../images/artunity/artunity-mockup-3.jpg';
import artunity4 from '../images/artunity/artunity-mockup-4.jpg';
import artunity5 from '../images/artunity/artunity-mockup-5.jpg';

import artunity_userflow_1 from '../images/artunity/artunity-login-userflow.jpg';
import artunity_userflow_2 from '../images/artunity/artunity-curator-show-userflow.jpg';
import artunity_userflow_3 from '../images/artunity/artunity-upload-userflow.jpg';

import artunity_sketch_1 from '../images/artunity/artunity-map-sketch.jpg';

import react from '../images/tools/react.png';
import harmonium from '../images/tools/harmonium.png';
import atom from '../images/tools/atom.png';
import nodejs from '../images/tools/nodejs.png';
import github from '../images/tools/github.png';
import sketch from '../images/tools/sketch.png';
import invision from '../images/tools/invision.png';
import craft from '../images/tools/craft.jpg';

import '../App.css';

const Artunity = () => {
  return (
    <div className="CaseStudy">

      <Row>
        <Col xlarge={7} medium={9} smallCentered>
          <section className="CaseStudy-wrapper">

            <Row>
              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
                <img src={artunity3} className="CaseStudy-img" alt="Artunity Mockup" />
              </Col>
              <Col>
                <h2 className="CaseStudy-header">Artunity</h2>
                <h4 className="CaseStudy-subheader">Mobile Web Application</h4>
                <p>
                  Artunity is a mobile web application where artists are discovered and monetize their art by selling Artunities. Artunity allows you an entry point into the buyers' world without any costly capital or marketing.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Role/Responsibility</h4>
                <p>
                  UX/UI Design, Front-End Development
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Tools Used</h4>
              </Col>

              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={react} alt="React" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={harmonium} alt="Harmonium" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={nodejs} alt="nodejs" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={atom} alt="Atom" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={github} alt="Github" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={sketch} alt="Sketch" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={invision} alt="Invision" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={craft} alt="Craft" />
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Duration/Timeline</h4>
                <p>
                  10 weeks
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Target Audience</h4>
                <p>
                  Artist – someone who shares and sells their art on Artunity
                  <br /><br />
                  Curator – fan, someone who views, shares, and buys Artunities or fully funds artwork on the Artunity app
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Story</h4>
                <p>
                  Artists don’t have time to market themselves and often lack the resources or time to crowdfund or sell their work. Artunity provides a platform for artists to be discovered locally or by their style of work and connected to fans and potential curator purchases.
                  <br /><br />
                  Artunity creates opportunities for artists to sell their artwork via raffle tickets called Artunities, which makes purchasing art more affordable to curators.
                  <br /><br />
                  Artunity has a system, conducted through algorithms, which randomly pulls a user's number out of a digital hat. Whichever user gets selected will then receive the artwork, with Artunity receiving a 15 percent cut of the transaction.
                  <br /><br />
                  Artists post their artwork and set the price per Artunity. Curators can save an Artunity to their curator gallery, share the Artunity on social media, purchase an Artunity, or fully fund an artwork by purchasing all remaining Artunities.
                  <br /><br />
                  Once all Artunities are funded, a winner to receive the original artwork is randomly chosen from among the curators who purchased Artunities. Artists can also opt for an early release if they have not sold all Artunities available.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Problem Statement/What are we trying to solve?</h4>
                <p>
                  Art enthusiasts, particularly millennials, don’t have the disposable income to outright buy art from their favorite artists. Much like a raffle, curators can purchase an Artunity, an opportunity to win the original, at a fraction of the cost.
                  <br /><br />
                  Jay Weinberg, the founder of Artunity and an artist himself, wanted to democratize art buying by helping artists connect with willing buyers, while also allowing curators who have an interest, but maybe not the means, to purchase art and support their favorite artists.
                  <br /><br />
                  Traditional galleries keep 50% of an artist’s sales. Artunity only takes 20% of the artist’s cut.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">UX Process</h4>
                <p>
                  Artunity provided the Revelry team with their branding and color scheme, along with some Balsamiq wireframes.
                  <br /><br />
                  From there, I created high fidelity mockups with the provided visuals to better nail down the User Interface. We were able to close any holes in the UX by doing some quick sketches, too. I also started implementing the styleguide so we had all of the components available.
                </p>
                <h5>High Fidelity Mockups:</h5>
              </Col>
              <Col>
                <img src={artunity_userflow_1} className="CaseStudy-img" alt="Artunity Userflow" />
              </Col>
              <Col>
                <img src={artunity_userflow_2} className="CaseStudy-img" alt="Artunity Userflow" />
              </Col>
              <Col>
                <img src={artunity_userflow_3} className="CaseStudy-img" alt="Artunity Userflow" />
              </Col>


              <Col>
                <p className="u-marginTop">
                  With the different user types (artist and curator), there were a few pain points we needed to nail down. I created some low fidelity wireframes and we addressed those concerns as a team, before building out the application.
                </p>
                <h5>Low Fidelity Wireframes:</h5>
              </Col>
              <Col>
                <img src={artunity_sketch_1} className="CaseStudy-img" alt="Artunity Sketch" />
              </Col>

              <Col>
                <p className="u-marginTop">
                  I worked on all of the front-end styling for the React mobile web application, and worked hand-in-hand with Engineers on our team, along with our Project Manager, and Jay, our client, while we implemented the application. 
                </p>
              </Col>
            </Row>{/* End UX Process Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Final Product</h4>
                <p>
                  The project took about 10 weeks, and we were able to 1. Create high fidelity mockups and close any UX gaps from their userflow and 2. Build out the entire mobile web application which is now available to check out!
                  <br /><br />
                  <a href="https://www.chicagotribune.com/suburbs/post-tribune/opinion/ct-ptb-davich-artunity-opportunity-st-0519-20170518-story.html" target="_blank">Read this article to learn more about Artunity!</a>
                </p>
              </Col>

              <Col>
                <img src={artunity1} className="CaseStudy-img" alt="Artunity Mockup" />
              </Col>
              <Col>
                <img src={artunity2} className="CaseStudy-img" alt="Artunity Mockup" />
              </Col>
              <Col>
                <img src={artunity4} className="CaseStudy-img" alt="Artunity Mockup" />
              </Col>
              <Col>
                <img src={artunity5} className="CaseStudy-img" alt="Artunity Mockup" />
              </Col>

              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
              </Col>
            </Row>{/* End Row Wrapper */}

          </section>
        </Col>
      </Row>

    </div>
  );
}

export default Artunity;