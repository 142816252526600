import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import cluster1 from '../images/cluster/cluster-mockup-1.jpg';
import cluster2 from '../images/cluster/cluster-mockup-2.jpg';

import cluster_research_1 from '../images/cluster/cluster-resources.jpg';
import cluster_research_2 from '../images/cluster/cluster-terminology-1.jpg';
import cluster_research_3 from '../images/cluster/cluster-terminology-2.jpg';
import cluster_research_4 from '../images/cluster/cluster-terminology-3.jpg';

import cluster_questions_1 from '../images/cluster/cluster-questions.jpg';
import cluster_questions_2 from '../images/cluster/cluster-questions-2.jpg';

import cluster_userflow_1 from '../images/cluster/cluster-userflow-1.jpg';
import cluster_userflow_2 from '../images/cluster/cluster-userflow-2.jpg';
import cluster_userflow_3 from '../images/cluster/cluster-userflow-3.jpg';
import cluster_userflow_4 from '../images/cluster/cluster-userflow-4.jpg';

import dtj4 from '../images/dtj/dtj-mockup-4.jpg';
import dtj5 from '../images/dtj/dtj-mockup-5.jpg';
import dtj6 from '../images/dtj/dtj-mockup-6.jpg';
import dtj7 from '../images/dtj/dtj-mockup-7.jpg';


import dtj_logo_1 from '../images/dtj/dtj-logos-1.jpg';
import dtj_logo_2 from '../images/dtj/dtj-logos-2.jpg';
import dtj_logo_3 from '../images/dtj/dtj-color-mock-1.jpg';
import dtj_logo_4 from '../images/dtj/dtj-color-mock-2.jpg';
import dtj_logo_5 from '../images/dtj/dtj-color-mock-3.jpg';

import sketch from '../images/tools/sketch.png';
import invision from '../images/tools/invision.png';
import craft from '../images/tools/craft.jpg';

import '../App.css';

const Cluster = () => {
  return (
    <div className="CaseStudy">

      <Row>
        <Col xlarge={7} medium={9} smallCentered>
          <section className="CaseStudy-wrapper">

            <Row>
              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
                <img src={cluster2} className="CaseStudy-img" alt="Cluster UI Mockup" />
              </Col>
              <Col>
                <h2 className="CaseStudy-header">Cluster UI</h2>
                <h4 className="CaseStudy-subheader">Revelry Services Product</h4>
                <p>
                  Revelry plans to offer a managed Kubernetes services product, "Revelry Cloud." Revelry Cloud will include high-touch, white glove set-up, monitoring, and support of Kubernetes-powered infrastructure on behalf of its clients. Cluster UI is the beginning phase of the Content Management System. I was part of the Cluster UI team in the initial phase of UX design, alongside another designer.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Role/Responsibility</h4>
                <p>
                  UX/UI Design, Product Design, UX Research
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Tools Used</h4>
              </Col>

              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={sketch} alt="Sketch" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={invision} alt="Invision" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={craft} alt="Craft" />
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Duration/Timeline</h4>
                <p>
                  2 months test
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Target Audience</h4>
                <p>
                  We are building for customers of the Revelry Cloud managed services product. We expect that Cluster UI will provide value to the follow users within that customer group:
                  <br /><br />
                  Managers / Leadership - with at least a semi-technical background – some understanding of the value of Kubernetes at companies that use or want to use Kubernetes.
                  <br /><br />
                  Engineers that are building and deploying applications and will need to interact with the deployment environment. This may include a combination of App Engineers and our customers' own DevOps Engineers.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Story</h4>
                <p>
                  Cluster UI is one feature of the Revelry Cloud offering. We hypothesize Cluster UI is a way to add value in addition to "just Kubernetes infrastructure."
                  <br /><br />
                  The UI will help answer questions such as, "What is in my cluster(s), is it healthy, what are these cloud resources costing, are we under- or over-utilizing the resources, what recent actions have occurred?" ... and more.
                  <br /><br />
                  As this UI product matures, we imagine enabling some limited Kubernetes administration and management tools, recognizing that for many operations, users will be engineers working from the command line.
                  <br /><br />
                  We also imagine providing "app store"-like capabilities to select and deploy K8s-compatible software (such as Revelry's Product Management suite of apps and integrations; as well as other Opens Source / 3rd party software).
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Problem Statement/What are we trying to solve?</h4>
                <p>
                  Cluster UI aims to provide easy insights into the most pressing questions customers have about their Kubernetes-powered cluster via a web UI.
                  <br /><br />
                  Kubernetes is difficult to understand. The entire Revelry Cloud managed services product attempts to alleviate customers' infrastructure concerns and provide them with the best cloud native environment possible without significant staff ramp-up.
                  <br /><br />
                  Cluster UI is focused on the problem of visibility into app resource use, cost, health, and availability; as well as the capacity to act on the insights provided.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">UX Process</h4>
                <p>
                  The team first did a design thinking challenge. We all took about an hour to do rounds of quick sketches to help solve the problem of how to show the Kubernetes data. We also wrote down questions that need to be solved. We presented our sketches with each other, and picked out ideas that we thought we could work with.
                  <br /><br />
                  Afterwards, I teamed up with another designer at Revelry to start the first phase of mockups. Kubernetes is a very difficult subject to grasp, so we took a few days to educate ourselves and do further UX research and thinking. 
                </p>
                <h5>Cluster UI Notes:</h5>
              </Col>
              <Col medium={6}>
                <img src={cluster_research_1} className="CaseStudy-img" alt="Cluster UI" />
              </Col>
              <Col medium={6}>
                <img src={cluster_research_2} className="CaseStudy-img" alt="Cluster UI" />
              </Col>
              <Col medium={6}>
                <img src={cluster_research_3} className="CaseStudy-img" alt="Cluster UI" />
              </Col>
              <Col medium={6}>
                <img src={cluster_research_4} className="CaseStudy-img" alt="Cluster UI" />
              </Col>
              <Col>
                <img src={cluster_questions_1} className="CaseStudy-img" alt="Cluster UI" />
              </Col>
              <Col>
                <img src={cluster_questions_2} className="CaseStudy-img" alt="Cluster UI" />
              </Col>


              <Col>
                <p className="u-marginTop">
                  I compiled questions for our DevOps team, and after getting a better understanding, I jumped into wireframes along with the other designer. We did a few rounds of wires, compiled more questions, did more user research, and so on. We combined our design ideas and moved forward.
                </p>
                <h5>Wireframes:</h5>
              </Col>
              <Col>
                <img src={cluster_userflow_1} className="CaseStudy-img" alt="Cluster UI" />
              </Col>
              <Col>
                <img src={cluster_userflow_2} className="CaseStudy-img" alt="Cluster UI" />
              </Col>
              <Col>
                <img src={cluster_userflow_3} className="CaseStudy-img" alt="Cluster UI" />
              </Col>
              <Col>
                <img src={cluster_userflow_4} className="CaseStudy-img" alt="Cluster UI" />
              </Col>

              <Col>
                <p className="u-marginTop">
                  Finally, after lots of going back-and-forth about Kubernetes clusters and how to best show this complicated UI, I took a turn and decided to nail down a clean, polished, high-fidelity mockup with dummy data and graphs that could be used for any scenario to best represent the data that we had trouble understanding. My main goal was to help the user to better understand Kubernetes with a data-driven, reliable dashboard, showing all insights they may need.
                </p>
              </Col>
            </Row>{/* End UX Process Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Final Mockup</h4>
                <p>
                  Once I finished the high fidelity mock, the team was satisfied and eager to move forward. From there, I handed the project off to another designer to start the branding for Moondog and front-end implementation. User testing would soon come after.
                </p>
              </Col>

              <Col>
                <img src={cluster1} className="CaseStudy-img" alt="Cluster UI" />
              </Col>
              <Col>
                <img src={cluster2} className="CaseStudy-img" alt="Cluster UI" />
              </Col>

              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
              </Col>
            </Row>{/* End Row Wrapper */}

          </section>
        </Col>
      </Row>

    </div>
  );
}

export default Cluster;