import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import about2 from '../images/profile-pic-1.jpg';
import about3 from '../images/profile-pic-2.jpg';
import about4 from '../images/profile-pic-4.jpg';
import Pdf from '../documents/BrittanyGay-Resume-2021.pdf';

import '../App.css';

const About = () => {
  return (
    <div className="About">
      
      <Row>
        <Col medium={10} smallCentered>

          <section>
            <Row className="About-wrapper">

              <Col xlarge={6}>
                <Row>
                  <Col medium={6}>
                    <img src={about3} className="About-img" alt="About Brittany Gay" />
                  </Col>
                  <Col medium={6}>
                    <img src={about4} className="About-img" alt="About Brittany Gay" />
                  </Col>
                  <Col>
                    <img src={about2} className="About-img Last" alt="About Brittany Gay" />
                  </Col>
                </Row>           
              </Col>

              <Col xlarge={6}>
                <h3>Hi! Thanks for coming by!</h3>
                <p>
                  I’m a Hybrid UX Designer & Front-End Developer with a people-focused design mentality that allows me to create a meaningful impact on products. I'm currently a Product Designer at Relativity based in Chicago, Illinois. I've been working in the tech industry for 10 years, specializing in UX, Product Design, and Front-End Development.
                  <br /><br />
                  Throughout my entire career I have worked with a diverse range of clients to meet their expectations. I work well with a team and thrive in a collaborative environment. I believe that one of the key components of good design is incorporating multiple perspectives to make sure that usability transfers across different user experiences.
                  <br /><br />
                  My technical skills give me a strong foundation to think creatively to solve problems and create better userflows. Once I start a project, I like to see it through from beginning to final completion. I am extremely detail oriented and I’m an inquisitive worker who is always looking for the best solution to a problem.
                  <br /> <br />
                  In my free time, I love to rollerskate, cook, collect records, watch live music, and play volleyball. Below is my resume, along with a link to connect. Feel free to reach out anytime!
                </p>

                <div className="rev-ButtonGroup">
                  <a className="rev-Button" href="mailto:brittanygay3@gmail.com">
                    Contact Me
                  </a>
                  <a className="rev-Button rev-Button--secondary" href={Pdf} target="_blank">
                    View Resume
                  </a>
                </div>

                <div className="Icon-wrapper">
                  <a className="Icon-social" href="https://www.linkedin.com/in/brittanygay/" target="_blank">
                    <Icon.Linkedin />
                  </a>

                  <a className="Icon-social" href="https://www.instagram.com/brittanygay/" target="_blank">
                    <Icon.Instagram />
                  </a>

                  <a className="Icon-social" href="https://twitter.com/LBGay" target="_blank">
                    <Icon.Twitter />
                  </a>

                  <a className="Icon-social" href="https://dribbble.com/LBGay" target="_blank">
                    <Icon.Dribbble />
                  </a>
                </div>
              </Col>

            </Row>{/* End About Wrapper */}
          </section>

        </Col>
      </Row>  

    </div>
  );
}

export default About;