import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import toc1 from '../images/toc/toc-mockup-1.jpg';
import toc2 from '../images/toc/toc-mockup-2.jpg';
import toc3 from '../images/toc/toc-mockup-3.jpg';
import toc4 from '../images/toc/toc-mockup-4.jpg';
import toc5 from '../images/toc/toc-mockup-5.jpg';

import toc_brand_1 from '../images/toc/toc-brand-guidelines-1.jpg';
import toc_brand_2 from '../images/toc/toc-brand-guidelines-2.jpg';

import toc_font_1 from '../images/toc/toc-mockup-font-examples.jpg';
import toc_font_2 from '../images/toc/toc-mockup-font-examples-2.jpg';
import toc_font_3 from '../images/toc/toc-mockup-font-examples-final.jpg';

import wordpress from '../images/tools/wordpress.png';
import nodejs from '../images/tools/nodejs.png';
import harmonium from '../images/tools/harmonium.png';
import vscode from '../images/tools/vs-code.png';
import invision from '../images/tools/invision.png';
import github from '../images/tools/github.png';
import sketch from '../images/tools/sketch.png';
import craft from '../images/tools/craft.jpg';

import '../App.css';

const Toc = () => {
  return (
    <div className="CaseStudy">

      <Row>
        <Col xlarge={7} medium={9} smallCentered>
          <section className="CaseStudy-wrapper">

            <Row>
              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
                <img src={toc1} className="CaseStudy-img" alt="Tales of the Cocktail Foundation" />
              </Col>
              <Col>
                <h2 className="CaseStudy-header">Tales of the Cocktail Foundation</h2>
                <h4 className="CaseStudy-subheader">Desktop Web Application</h4>
                <p>
                  The Tales of the Cocktail Foundation is a non-profit organization focused on spirits education and the hospitality industry. They are a platform to help face issues in the global drinks industry.
                  <br /><br />
                  We built the Tales of the Cocktail Foundation a web application that better conveys their brand, and highlights their foundation, and easily navigate through new and existing content.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Role/Responsibility</h4>
                <p>
                  UX/UI Design, Product Design, Front-End Development
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Tools Used</h4>
              </Col>

              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={wordpress} alt="Wordpress" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={nodejs} alt="Node JS" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={vscode} alt="VS Code" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={harmonium} alt="Harmonium" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={github} alt="Github" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={sketch} alt="Sketch" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={invision} alt="invision" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={craft} alt="craft" />
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Duration/Timeline</h4>
                <p>
                  2-3 months
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Target Audience</h4>
                <p>
                  The target audience for the Tales of the Cocktail Foundation consists of users that are interested in, support, or interact with the organization or it's yearly flagship festival event.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Story</h4>
                <p>
                  Tales of the Cocktail Foundation is a non-profit organization in spirits education, and a platform that helps face issues in the global drinks industry. The Tales of the Cocktail Foundation’s mission is to educate, advance, and support the industry through programs that will benefit and make a lasting impact on the host community.
                  <br /><br />
                  Tales of the Cocktail holds events, programs, seminars, and more. They also have a grants program and ways to volunteer. They are very well known throughout the New Orleans community and beyond. 
                  <br /><br />
                  Their main mission in education involves advancing the hospitality industry. They have programs that help connect the community to industry leaders through their seminars.
                  <br /><br />
                  The Tales of the Cocktail Foundation is also a platform for leadership and development of standards around issues they face in the industry. They have professional networking and building opportunities to better connect their community.
                  <br /><br />
                  Lastly, the Tales of the Cocktail Foundation shows support through financial and promotional programs geared towards those who define the past and future of the hospitality industry. They provide resources in education, healthcare, diversity, and more.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Problem Statement/What are we trying to solve?</h4>
                <p>
                  The main problem we are trying to solve is that the current organization’s website does not match with the brand goals. Their current website is very out-dated, hard to navigate, and not user-friendly. It’s hard to view their wide-range of existing content, along with any new content added.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">UX Process</h4>
                <p>
                  The Tales of the Cocktail Foundation provided us with their existing logo and branding, along with a branding guideline creating by a third party source. They also provided us with their wide range of professional photography and videos from all of their events (which was a great asset to have).
                </p>
                <h5>Tales of the Cocktail Branding Guidlines:</h5>
              </Col>
              <Col medium={6}>
                <img src={toc_brand_1} className="CaseStudy-img" alt="Tales of the Cocktail Guidelines" />
              </Col>
              <Col medium={6}>
                <img src={toc_brand_2} className="CaseStudy-img" alt="Tales of the Cocktail Guidelines" />
              </Col>

              <Col>
                <p className="u-marginTop">
                  For this project, I worked with two other designers at Revelry, along with our Product Manager and an Engineer. Together, we scheduled calls with the client to do some design thinking tasks. We went through rounds of font choices, along with do’s and don’ts pertaining to their photography guidelines. 
                </p>
                <h5>My Font Choices for the Client:</h5>
              </Col>
              <Col>
                <img src={toc_font_1} className="CaseStudy-img" alt="Tales of the Cocktail Guidelines" />
              </Col>
              <Col>
                <img src={toc_font_2} className="CaseStudy-img" alt="Tales of the Cocktail Guidelines" />
              </Col>
              <Col>
                <img src={toc_font_3} className="CaseStudy-img" alt="Tales of the Cocktail Guidelines" />
              </Col>

              <Col>
                <p className="u-marginTop">
                  The designers split up, and we all did an initial round of sketches, then moved onto high fidelity mocks in Sketch. We all presented our work amongst the team, and decided on the right direction in order to move forward.
                  <br /><br />
                  We picked a few assets that we liked from all of our mockups, and we chose the main direction that I designed for the Homepage Header, Events Calendar, and more. We chose a content section from each designer’s mocks, along with the drop-down menu, and from there, worked on making our thoughts cohesive.  
                  <br /><br />
                  I worked together with one other designer on the front-end styling for the web application. We chose to use Wordpress for this application, because it provides an easy content management system for the client to use. The client is responsible for adding new content once we finish off the project.
                </p>
              </Col>

            </Row>{/* End UX Process Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Final Product</h4>
                <p>
                  The project took about 2-3 months, and then it was handed off to others to continue to work on any smaller bugs or fixes. Due to COVID-19, the Tales of the Cocktail events have been postponed or moved virtually, and they are now providing resources to help hospitality workers and others in need, which is all listed on their website.
                  <br /><br />
                  <a href="https://talesofthecocktail.org/" target="_blank">Check it out here!</a>
                </p>
              </Col>

              <Col>
                <img src={toc2} className="CaseStudy-img" alt="Tales of the Cocktail Foundation" />
              </Col>
              <Col>
                <img src={toc3} className="CaseStudy-img" alt="Tales of the Cocktail Foundation" />
              </Col>
              <Col>
                <img src={toc4} className="CaseStudy-img" alt="Tales of the Cocktail Foundation" />
              </Col>
              <Col>
                <img src={toc5} className="CaseStudy-img" alt="Tales of the Cocktail Foundation" />
              </Col>



              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
              </Col>
            </Row>{/* End Row Wrapper */}

          </section>
        </Col>
      </Row>

    </div>
  );
}

export default Toc;