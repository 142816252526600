import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import bigneon2 from '../images/bigneon/bn-mockup-2.jpg';
import bigneon3 from '../images/bigneon/bn-mockup-3.png';
import bigneon4 from '../images/bigneon/bn-mockup-4.jpg';
import bigneon5 from '../images/bigneon/bn-mockup-1.jpg';

import bigneon_mocks_1 from '../images/bigneon/big-neon-checkout-userflow.jpg';
import bigneon_mocks_2 from '../images/bigneon/big-neon-search-userflow.jpg';

import bigneon_sketch_1 from '../images/bigneon/big-neon-sketch-1.jpg';
import bigneon_sketch_2 from '../images/bigneon/big-neon-sketch-2.jpg';

import bigneon_gif_1 from '../images/bigneon/big-neon-gif-1.gif';
import bigneon_gif_2 from '../images/bigneon/big-neon-gif-6.gif';
import bigneon_gif_3 from '../images/bigneon/big-neon-gif-4.gif';
import bigneon_gif_4 from '../images/bigneon/big-neon-gif-5.gif';

import reactnative from '../images/tools/react-native.png';
import nodejs from '../images/tools/nodejs.png';
import xcode from '../images/tools/xcode.jpg';
import expo from '../images/tools/expo.png';
import atom from '../images/tools/atom.png';
import github from '../images/tools/github.png';
import sketch from '../images/tools/sketch.png';

import '../App.css';

const Bigneon = () => {
  return (
    <div className="CaseStudy">

      <Row>
        <Col xlarge={7} medium={9} smallCentered>
          <section className="CaseStudy-wrapper">

            <Row>
              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
                <img src={bigneon2} className="CaseStudy-img" alt="Big Neon" />
              </Col>
              <Col>
                <h2 className="CaseStudy-header">Big Neon</h2>
                <h4 className="CaseStudy-subheader">Mobile Application, iOS & Android</h4>
                <p>
                  Big Neon is an event ticketing application that transforms the way music fans buy tickets and experience shows. It’s led by an experienced team, including the co-founder of Ticketfly. It is a React Native app for iOS and Android.
                  <br /><br />
                  Revelry participated in the open source contribution to develop the Big Neon application for their team. Revelry’s team consisted of one engineer, a project manager, and a UX Designer/Front-End Developer.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Role/Responsibility</h4>
                <p>
                  Front-End Development, UX Design direction when needed
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Tools Used</h4>
              </Col>

              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={reactnative} alt="React Native" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={nodejs} alt="Node JS" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={xcode} alt="Xcode" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={expo} alt="Expo" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={atom} alt="Atom" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={github} alt="Github" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={sketch} alt="Sketch" />
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Compatibility Targets</h4>
                <p>
                  <strong>iOS (10 and above)</strong> -  iPhone 6 and above
                  <br />
                  <strong>Android (4.4 and above)</strong> - Samsung S6, S7 and S8 families
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Duration/Timeline</h4>
                <p>
                  1 year
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Target Audience</h4>
                <p>
                  Event Attendees: People interested in attending events and purchasing tickets to those events.
                  <br /><br />
                  Event Ticket Holders: People interested in selling their tickets to events.
                  <br /><br />
                  Event Sponsor: Venues, Promoters, etc that host events and make tickets available to purchase for those events
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Story</h4>
                <p>
                  Big Neon is a Mobile Application for event ticket exchange. Users can buy, sell and transfer tickets. This could be a platform for event goers as well as Sponsors distributing tickets to their events. The main draw is that the tickets are solely digital assets and exchanged on top of Tari for secure digital asset transactions.
                  <br /><br />
                  Big Neon is the piece for a ticket exchange. The fact that it is built on Tari solves the problem of “economic leakage,” where middlemen reap all the revenue from the resale of virtual goods. Tari will be designed to help compensate original “owners,” like artists, sports teams, event promoters, and other parties.
                  <br /><br />
                  Tari is a new open source, digital assets focused blockchain protocol that is being architected as a merge-mined sidechain with Monero. They chose to focus specifically on digital assets – things like tickets, loyalty points, in-game items, and crypto-native assets like CryptoKitties – because they see a huge opportunity to revolutionize the way these assets are owned, managed and transferred.
                  <br /><br />
                  A digital asset is one that consumers can purchase or earn the right to use, but which exists entirely in digital form. Digital assets include event tickets, in-game items, loyalty points, and cryptonative assets.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Problem Statement/What are we trying to solve?</h4>
                <p>
                  Event Attendees: Need to be able to browse/search for events they want to attend and purchase tickets to those events.
                  <br /><br />
                  Event Tickets Holders: Need to transfer or Sell their own tickets to interested attendees.
                  <br /><br />
                  Event Sponsors: Need to list tickets for sale to events that they sponsor.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">UX Process</h4>
                <p>
                  Big Neon provided us with high fidelity Sketch mockups, created by their team. My main role on the project was to build out the front-end of the React Native app, based on their provided mocks. The app is heavily focused on using animations and transitions to better the user experience overall.
                </p>
                <h5>High Fidelity Mocks Provided by Big Neon:</h5>
              </Col>
              <Col>
                <img src={bigneon_mocks_1} className="CaseStudy-img" alt="Big Neon Mocks" />
              </Col>
              <Col>
                <img src={bigneon_mocks_2} className="CaseStudy-img" alt="Big Neon Mocks" />
              </Col>

              <Col>
                <p className="u-marginTop">
                  The team allowed me to provide any UX suggestions and feedback if the user flow didn’t work well. If there was a UX hole, or the flow didn’t work as intended, I was able to provide my expertise on ways to better the user flow. I was also given the ability to mock up any screens that weren’t provided, which in turn was approved by their side before we started implementation.
                </p>
                <h5>Sketches of UX Suggestions:</h5>
              </Col>
              <Col medium={6}>
                <img src={bigneon_sketch_1} className="CaseStudy-img" alt="Big Neon Sketches" />
              </Col>
              <Col medium={6}>
                <img src={bigneon_sketch_2} className="CaseStudy-img" alt="Big Neon Sketches" />
              </Col>

              <Col>
                <p className="u-marginTop">
                  I worked on all of the front-end development for the React Native app, and worked hand-in-hand with our Engineer on the team for all of the animations that required additional React plugins.
                </p>
                <h5>Gifs showing the final coded app with transitions and animations:</h5>
              </Col>
              <Col medium={6}>
                <img src={bigneon_gif_1} className="CaseStudy-img" alt="Big Neon Gif" />
              </Col>
              <Col medium={6}>
                <img src={bigneon_gif_2} className="CaseStudy-img" alt="Big Neon Gif" />
              </Col>

            </Row>{/* End UX Process Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Final Product</h4>
                <p>
                  Once the app was ready to go live, we compiled all assets needed to submit to the Apple App Store for iPhone. You can now download the app to check out yourself! 
                  <br /><br />
                  <a href="https://apps.apple.com/us/app/big-neon/id1445600728" target="_blank">Download the Big Neon App!</a>
                </p>
              </Col>

              <Col>
                <img src={bigneon3} className="CaseStudy-img" alt="Big Neon Image 3" />
              </Col>
              <Col>
                <img src={bigneon4} className="CaseStudy-img" alt="Big Neon Mocks" />
              </Col>

              <Col medium={6}>
                <img src={bigneon_gif_3} className="CaseStudy-img" alt="Big Neon Gif" />
              </Col>
              <Col medium={6}>
                <img src={bigneon_gif_4} className="CaseStudy-img" alt="Big Neon Gif" />
              </Col>

              <Col>
                <img src={bigneon5} className="CaseStudy-img" alt="Big Neon Mocks" />
              </Col>

              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
              </Col>
            </Row>{/* End Row Wrapper */}

          </section>
        </Col>
      </Row>

    </div>
  );
}

export default Bigneon;