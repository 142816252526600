import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import dtj1 from '../images/dtj/dtj-mockup-1.jpg';
import dtj2 from '../images/dtj/dtj-mockup-2.jpg';
import dtj3 from '../images/dtj/dtj-mockup-3.jpg';
import dtj4 from '../images/dtj/dtj-mockup-4.jpg';
import dtj5 from '../images/dtj/dtj-mockup-5.jpg';
import dtj6 from '../images/dtj/dtj-mockup-6.jpg';
import dtj7 from '../images/dtj/dtj-mockup-7.jpg';

import dtj_sketch_1 from '../images/dtj/dtj-sketch-1.jpg';
import dtj_sketch_2 from '../images/dtj/dtj-sketch-3.jpg'; 

import dtj_logo_1 from '../images/dtj/dtj-logos-1.jpg';
import dtj_logo_2 from '../images/dtj/dtj-logos-2.jpg';
import dtj_logo_3 from '../images/dtj/dtj-color-mock-1.jpg';
import dtj_logo_4 from '../images/dtj/dtj-color-mock-2.jpg';
import dtj_logo_5 from '../images/dtj/dtj-color-mock-3.jpg';

import react from '../images/tools/react.png';
import harmonium from '../images/tools/harmonium.png';
import atom from '../images/tools/atom.png';
import nodejs from '../images/tools/nodejs.png';
import github from '../images/tools/github.png';
import sketch from '../images/tools/sketch.png';
import illustrator from '../images/tools/illustrator.png';
import coolors from '../images/tools/coolors.png';

import '../App.css';

const Dtj = () => {
  return (
    <div className="CaseStudy">

      <Row>
        <Col xlarge={7} medium={9} smallCentered>
          <section className="CaseStudy-wrapper">

            <Row>
              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
                <img src={dtj3} className="CaseStudy-img" alt="Digital Tip Jar Mockup" />
              </Col>
              <Col>
                <h2 className="CaseStudy-header">Digital Tip Jar</h2>
                <h4 className="CaseStudy-subheader">Desktop & Mobile Web Application</h4>
                <p>
                  Digital Tip Jar is an easy to use web app that enables performing artists to accept tips in the form of electronic payment.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Role/Responsibility</h4>
                <p>
                  UX/UI Design, Branding, Product Design, Front-End Development
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Tools Used</h4>
              </Col>

              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={react} alt="React" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={harmonium} alt="Harmonium" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={nodejs} alt="nodejs" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={atom} alt="Atom" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={github} alt="Github" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={illustrator} alt="Illustrator" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={sketch} alt="Sketch" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={coolors} alt="Coolors" />
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Duration/Timeline</h4>
                <p>
                  1-2 months
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Target Audience</h4>
                <p>
                  The target audience for Digital Tip Jar is intended for artists and musicians in the New Orleans community and beyond.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Story</h4>
                <p>
                  Digital Tip Jar is an easy to use web app that enables performing artists to accept tips in the form of electronic payment. They provide an easy way for musicians to get the tips that they deserve.
                  <br /><br />
                  Digital Tip Jar started in 2013, when founder Patrick Crilly and his business partner, Bryan Joseph, entered a hackathon sponsored by CodeMkrs. There, they came up with Digital Tip Jars, based on QR codes. A few big names picked up on Digital Tip Jar, including Austin City Limits and South by Southwest. During their time working with ACL and SXSW, they realized that QR codes weren’t cutting it in terms of user experience. The user had to install the app before scanning the QR code, which made the UX difficult, especially at a live show.
                  <br /><br />
                  After going back to the drawing board, Crilly came up with a “text to tip” feature. The feature allowed users to text the artist’s ID to their phone number and receive a text with a URL to complete the tip.
                  <br /><br />
                  This is where my team soon came into the mix, and we joined forces to work on perfecting the branding and web application for Digital Tip Jar. 
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Problem Statement/What are we trying to solve?</h4>
                <p>
                  Digital Tip Jar's main mission is to help artists receive tips within a cashless society. The app is intended to be simple and easy to use. 
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">UX Process</h4>
                <p>
                  The clients came to us to create the product branding and simple website application for Digital Tip Jar. Their main idea was to emphasize the connection and gratitude cultivated between artists and supporters as an important part of the interaction. This had to be shown in the branding. 
                  <br /><br />
                  As the UX Designer on the project, I was responsible for creating the logo, color scheme, and overall brand of the product. I was also responsible for the front-end development of their simple web application. I worked with an Engineer, a Project Manager, and the clients.
                </p>
                <h5>Rough Sketches/Initial Logo Ideas:</h5>
              </Col>
              <Col>
                <img src={dtj_sketch_1} className="CaseStudy-img" alt="Digital Tip Jar Sketch" />
              </Col>
              <Col>
                <img src={dtj_sketch_2} className="CaseStudy-img" alt="Digital Tip Jar Sketch" />
              </Col>


              <Col>
                <p className="u-marginTop">
                  We started with the product branding. I first did rounds of sketches, then formulated them into logo concepts within Adobe Illustrator. I showcased 12 initial options, keeping the idea of showing the gratitude between artist and supporter.
                </p>
                <h5>Logo Versions:</h5>
              </Col>
              <Col>
                <img src={dtj_logo_1} className="CaseStudy-img" alt="Digital Tip Jar Logo" />
              </Col>
              <Col>
                <img src={dtj_logo_2} className="CaseStudy-img" alt="Digital Tip Jar Logo" />
              </Col>

              <Col>
                <p className="u-marginTop">
                  After discussing what we liked/disliked about the options, I went onto V3 of logo concepts, and presented 6 final logos. At the same time, I also presented a few compiled color schemes using the Coolors app. Once we decided on the color scheme, that’s where “Tippy”, the logo came to life. The logo combines the wi-fi signal coming out of an old-school piggy bank. The logo represents a nostalgic feeling of connection and support, something you may not find on other e-commerce transactions.
                </p>
                <h5>Final Logo Ideas with Color Schemes:</h5>
              </Col>

              <Col medium={6}>
                <img src={dtj_logo_3} className="CaseStudy-img" alt="Digital Tip Jar Logo" />
              </Col>
              <Col medium={6}>
                <img src={dtj_logo_4} className="CaseStudy-img" alt="Digital Tip Jar Logo" />
              </Col>
              <Col medium={6}>
                <img src={dtj_logo_5} className="CaseStudy-img" alt="Digital Tip Jar Logo" />
              </Col>

              <Col>
                <p className="u-marginTop">
                  Now that we had the branding finalized, it was time to start working on the application. The app’s primary use is on mobile, so we decided to build mobile first. We needed four main core loops:
                  <br /><br />
                  1. Login or Register
                  <br /><br />
                  2. Search for Artists
                  <br /><br />
                  3. Tip Artists and leave a comment
                  <br /><br />
                  4. View feed with comments from users who have tipped
                </p>
              </Col>
            </Row>{/* End UX Process Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Final Product</h4>
                <p>
                  The intention of the application is to keep a very simple UI, so it’s easy for the user to navigate, tip, and get back to their live music. The overall vibe of Digital Tip Jar conveys the connection between artist and supporter, and the gratitude that is being passed between the two. The comments feed is a fun way to see who else is tipping, along with any recommendations from users to check out new artists to support.  
                  <br /><br />
                  Digital Tip Jar was able to raise thousands of dollars from providing the link through artist’s social media.
                </p>
              </Col>

              <Col>
                <img src={dtj7} className="CaseStudy-img" alt="Digital Tip Jar Mockup" />
              </Col>
              <Col>
                <img src={dtj4} className="CaseStudy-img" alt="Digital Tip Jar Mockup" />
              </Col>
              <Col>
                <img src={dtj6} className="CaseStudy-img" alt="Digital Tip Jar Mockup" />
              </Col>
              <Col>
                <img src={dtj5} className="CaseStudy-img" alt="Digital Tip Jar Mockup" />
              </Col>

              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
              </Col>
            </Row>{/* End Row Wrapper */}

          </section>
        </Col>
      </Row>

    </div>
  );
}

export default Dtj;