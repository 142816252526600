import React, { Component } from 'react'
import logo from '../images/logo.png';
import Pdf from '../documents/BrittanyGay-Resume-2021.pdf';
import Drawer from '../Drawer.js';
import { NavLink } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';


const Navigation = () => { 
  return (
    <div className="rev-TopBar">
      <ScrollToTop>
        <nav role="navigation">

          <div class="u-flexAlignStart">
            <div class="rev-TopBar-title">
              <a href="/" class="rev-Brand">
                <img src={logo} className="Topbar-logo" alt="Brittany Gay Design logo" />
              </a>
            </div>

            <div class="rev-TopBar-item">
              <ul className="rev-Menu rev-Menu--horizontalLeft">
                <li class="rev-Menu-item">
                  <NavLink to="/about">About</NavLink>
                </li>
                <li class="rev-Menu-item">
                  <NavLink to="/portfolio">Portfolio</NavLink>
                </li>
                <li class="rev-Menu-item">
                  <NavLink to="/about">Contact</NavLink>
                </li>
              </ul>
            </div>    
          </div>

          <div class="rev-TopBar-item">
            <ul className="rev-Menu rev-Menu--horizontalRight">
              <li class="rev-Menu-item">
                <div className="Menu-button">
                  <a href={Pdf} target="_blank">View Resume</a>
                </div>
              </li>
            </ul>
          </div> 

          <Drawer
            expanderChildren="+"
            closerChildren="+"
            className="MobileMenu"
            right
            fixed
          >
            <div className="rev-Drawer-contents">
              <ul className="rev-Menu">
                <li class="rev-Menu-item">
                  <NavLink to="/about">About</NavLink>
                </li>
                <li class="rev-Menu-item">
                  <NavLink to="/portfolio">Portfolio</NavLink>
                </li>
                <li class="rev-Menu-item">
                  <NavLink to="/about">Contact</NavLink>
                </li>
                <li class="rev-Menu-item">
                  <a href={Pdf} target="_blank">Resume</a>
                </li>
              </ul>
            </div>
            
          </Drawer>  

        </nav>
      </ScrollToTop>
    </div>
  );
}

export default Navigation;