import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './components/Home';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Splash from './components/Splash';
import Error from './components/Error';
import Navigation from './components/Navigation';
import Footer from './components/Footer';

import Tpn from './components/Tpn';
import Bigneon from './components/Bigneon';
import Lidcore from './components/Lidcore';
import Toc from './components/Toc';
import Artunity from './components/Artunity';
import Dtj from './components/Dtj';
import Cluster from './components/Cluster';
import Estate from './components/Estate';


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Navigation />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/splash" component={Splash} />

            <Route path="/tpn" component={Tpn} />
            <Route path="/bigneon" component={Bigneon} />
            <Route path="/lidcore" component={Lidcore} />
            <Route path="/toc" component={Toc} />
            <Route path="/artunity" component={Artunity} />
            <Route path="/dtj" component={Dtj} />
            <Route path="/cluster" component={Cluster} />
            <Route path="/estateclarity" component={Estate} />

            <Route component={Error} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
