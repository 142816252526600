import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import lidcore3 from '../images/lidcore/lidcore-mockup-3.jpg';
import lidcore5 from '../images/lidcore/lidcore-mockup-4.jpg';
import lidcore6 from '../images/lidcore/lidcore-mockup-5.jpg';
import lidcore7 from '../images/lidcore/lidcore-mockup-7.jpg';
import lidcore8 from '../images/lidcore/lidcore-mockup-8.jpg';

import lidcore_sketch_1 from '../images/lidcore/lidcore-sketch-1.jpg';
import lidcore_sketch_2 from '../images/lidcore/lidcore-sketch-3.jpg';

import lidcore_userflow_1 from '../images/lidcore/lidcore-cases-1.png';

import lidcore_detection_1 from '../images/lidcore/lidcore-detection-1.jpg';
import lidcore_detection_2 from '../images/lidcore/lidcore-detection-2.jpg';
import lidcore_detection_3 from '../images/lidcore/lidcore-detection-3.jpg';
import lidcore_detection_4 from '../images/lidcore/lidcore-detection-4.jpg';
import lidcore_detection_5 from '../images/lidcore/lidcore-detection-5.jpg';
import lidcore_detection_6 from '../images/lidcore/lidcore-detection-6.jpg';

import rails from '../images/tools/rails.png';
import harmonium from '../images/tools/harmonium.png';
import atom from '../images/tools/atom.png';
import nodejs from '../images/tools/nodejs.png';
import github from '../images/tools/github.png';
import sketch from '../images/tools/sketch.png';

import '../App.css';

const Lidcore = () => {
  return (
    <div className="CaseStudy">

      <Row>
        <Col xlarge={7} medium={9} smallCentered>
          <section className="CaseStudy-wrapper">

            <Row>
              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
                <img src={lidcore3} className="CaseStudy-img" alt="Lidcore Image" />
              </Col>
              <Col>
                <h2 className="CaseStudy-header">Lidcore, LicenseID &trade;</h2>
                <h4 className="CaseStudy-subheader">Content Management System</h4>
                <p>
                  Lidcore is a technology company that has developed an application to enable the identification of intellectual property along with its associated data. Their initial product is called LicenseID, and this technology uses watermarking to encode data into media each time it is uploaded or downloaded for licensing and distribution. This allows holders to track copyrights and prevent infringement. 
                  <br /><br />
                  We helped Lidcore design and develop their web application for tracking this data.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Role & Timeline</h4>
                <p>
                  UX/UI Design, Product Design, Front-End Development, UX Research
                  <br /> <br />
                  1 year
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Tools Used</h4>
              </Col>

              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={rails} alt="Rails" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={harmonium} alt="Harmonium" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={nodejs} alt="nodejs" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={atom} alt="Atom" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={github} alt="Github" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={sketch} alt="Sketch" />
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Target Audience</h4>
                <p>
                  The target audience for Lidcore is music and audio rights holders, so they can profit from the use of their work and prevent fraud.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Problem Statement/What are we trying to solve?</h4>
                <p>
                  There is no consistent and reliable way to identify and track IP. New digital distribution platforms on the internet have only increased the problems for IP users. Billions of dollars are lost annually due to a lack of technology for tagging, tracking, compensating and protecting creative works. To build sustainable businesses, copyright owners need a holistic solution to bring transparency to the system.
                  <br /> <br />
                  Lidcore’s technology leverages watermarking to encode data into copyrighted media each time it’s uploaded or downloaded for licensing and distribution. The embedded data communicates this information to third parties, so rights holders can profit from the use of their work and prevent fraud.
                  <br /><br />
                  Lidcore’s disruptive technology offerings give copyright owners the ability to encode rights data and end user information directly into digital files. It’s dynamic system makes it possible to update this data in real-time.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">UX Process</h4>
                <p>
                  As Lidcore's Lead Designer, I was more than eager to get started on a project like this, since I have a strong passion for the music industry and believe that audio rights holders need a consistent way to track their work.
                  <br /><br />
                  For each new feature, I would first discuss with the team, do some UX thinking and problem solving, followed by sketches which then turned into high fidelity mocks if needed. The main goal was to create a simplified content management system that the user can easily use to maintain and protect their data.
                </p>
                <h5>Examples of Sketches from userflows:</h5>
              </Col>
              <Col>
                <img src={lidcore_sketch_1} className="CaseStudy-img" alt="Lidcore Sketch 1" />
              </Col>
              <Col>
                <img src={lidcore_sketch_2} className="CaseStudy-img" alt="Lidcore Sketch 2" />
              </Col>

              <Col>
                <h4 className="CaseStudy-sectionHeader">Case Study: Detection Listing Dashboard</h4>
                <p className="u-marginTop">
                  The Open Cases Dashboard shows all open Detection cases for that user. From there, the user can drill down to view the infringement number, total value, settlement documents, and each time that case was illegally used. One big feature within this userflow is the Detection Listing Dashboard showing all new Detections. The user has the ability to archive the Detection or assign the Detection to an open case and a Licensor.
                  <img src={lidcore_userflow_1} className="CaseStudy-img Border" alt="Lidcore Userflow 1" />
                  <small class="CaseStudy-disclaimer">Open Cases Dashboard</small>
                </p>
                <h5>User Problem</h5>
                <p>
                  Based on user research conducted by their team, users want to have the ability to easily scan through each Detection within the Dashboard and either assign or dismiss a case without having to navigate away from the page. Both of these actions need to be seamless, quick and as easy as possible for our users.
                </p>

                <h5>Proposed Solution</h5>
                <p> 
                  Since the Detections within this Dashboard are best shown in a Table format, my proposed solution involves adding an Accordion Dropdown under each Detection Row, with the ability to archive or assign each one. This way, it isn't intrusive like a Modal/Popup, and it's quick for the user to scroll through and assign as they go, without navigating away from the page.
                </p>
                <h5>Detection Listing Dashboard Prototypes:</h5>
              </Col>
              <Col>
                <img src={lidcore_detection_3} className="CaseStudy-img Border" alt="Lidcore Detection 3" />
                <p>The "Assign" and "Archive" buttons that sit to the right of each row within the Table allow the user to trigger the dropdown Accordion to perform their set action for that Detection. The dropdown form for "Assigning" allows the user to assign the selected Detection to an open case and licensor.</p>
              </Col>
              <Col>
                <img src={lidcore_detection_4} className="CaseStudy-img Border" alt="Lidcore Detection 4" />
                <p>The user then submits their assignment, which directs them to a confirmation notification within the same Dropdown Accordion. The confirmation notification provides a link to the assigned case. From there, the Detection becomes an "infringement" shown on the Case Detail page in the Infringements Table.</p>
                <p>Once the Detection is assigned and closed out, the list refreshes, removes the assigned case and re-orders the table.</p>
              </Col>
              <Col>
                <img src={lidcore_detection_5} className="CaseStudy-img Border" alt="Lidcore Detection 5" />
                <p>Archiving a Detection is identical to the "Assign" Accordion. The user has the abiity to archive the detection. If confirmed, the Detection will be moved to the "Resolved Detections" page, which is easy to navigate to from the link on the top right of the page.</p>
              </Col>
              
              <Col>
                <h5>User Testing/Feedback</h5>
                <p>
                  I was not part of the user testing process for this project, but this is what I would have done if given the opportunity:
                </p>
                <p>
                  I would have conducted some A/B testing, by showing the users the proposed solution above with the Accordions, as well as a version with Modals/Popups. This would give us a chance to see if our solution is the easiest method of assigning Detections, or if it causes more confusion than needed within the Table itself.
                </p>
                <p>
                  From there, I would conduct some more qualitative usability testing, as well as an accessibility evaluation. I would test with current users, as well as new users, to gather as much data as I can. I would make sure users are able to navigate to see their list of infringements after each Detection is assigned, as well as if they can easily find the Resolved Detections page from the link at the top right of the Dashboard. Conducting an accessibility evaluation afterwards will ensure everything is up to standards, and if not, it's important to also make those needed changes.
                </p>
              </Col>

            </Row>{/* End UX Process Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Product Outcome</h4>
                <p>
                  The project took almost a year to complete, and over that period of time, I worked on numerous userflows and features for the application, from the very beginning phases of ideation and UX thinking, all the way to the implementation and hand-off to Engineers. The final product showcases a clean user inferface, easy to use functionality, as well as easy navigation. 
                  <br /><br />
                  Since launch, Lidcore has recovered over $2 million in settlements for Audiosocket and the system identifies more than $30k in copyrights violations for that same rights holder every week.
                </p>
              </Col>

              <Col>
                <img src={lidcore6} className="CaseStudy-img" alt="Lidcore Image" />
              </Col>
              <Col>
                <img src={lidcore7} className="CaseStudy-img" alt="Lidcore Image" />
              </Col>
              <Col>
                <img src={lidcore5} className="CaseStudy-img" alt="Lidcore Image" />
              </Col>
              <Col>
                <img src={lidcore8} className="CaseStudy-img" alt="Lidcore Image" />
              </Col>


              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
              </Col>
            </Row>{/* End Row Wrapper */}

          </section>
        </Col>
      </Row>

    </div>
  );
}

export default Lidcore;