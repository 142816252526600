import React from 'react';

const Footer = () => {
  return (
    <div className="Footer">
      <p>© Copyright 2023. Website design & development by Brittany Gay</p>
    </div>
  );
}

export default Footer;