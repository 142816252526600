import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Row, Col} from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import logo from '../images/logo.png';

import about2 from '../images/profile-pic-1.jpg';

import tpn_events_desktop from '../images/tpn/tpn-events-desktop.jpg';
import bigneon from '../images/bigneon/bn-mockup-1.jpg';
import lidcore from '../images/lidcore/lidcore-mockup-3.jpg';

import blog1 from '../images/blog-1.jpg';
import blog2 from '../images/blog-2.jpg';
import blog3 from '../images/blog-3.jpg';

import '../App.css';

const home = () => {
  return (
    <div className="Homepage">

      <Row className="Homepage-top">
        <Col xlarge={9} medium={10} smallCentered>

          <Row flex className="Homepage-banner">
            <Col small={12} medium={7} smallOrder={2} largeOrder={1}>
              <header>
                <h1>
                  Brittany Gay.<br />
                  UX <strong>Designer</strong>.<br />
                  Front-End <strong>Developer</strong>.
                </h1>
                <p>
                  I’m a Hybrid UX Designer & Front-End Developer with a people-focused design mentality that allows me to create a meaningful impact on products.
                </p>
                <a
                  className="rev-Button"
                  href="/portfolio"
                  rel="noopener noreferrer"
                >
                  View Portfolio
                </a>
              </header>
            </Col>

            <Col small={12} medium={5} smallOrder={1} largeOrder={2}>
              <img src={logo} className="Splash-logo" alt="Brittany Gay Design logo" />
            </Col>
          </Row>{/* End Homepage Banner */}

        </Col>
      </Row>


      <section className="Section-grid">
        <Row>
          <Col xlarge={9} medium={10} smallCentered>

            <h3 className="Text-center">Featured Work</h3>

            <Row>
              <Col>
                <div className="rev-Card CardImage CardImageLeft">

                  <div className="rev-Card-header">
                    <a href="/tpn">
                      <img src={tpn_events_desktop} className="CaseStudy-img" alt="TPN Events Desktop" />
                    </a>  
                  </div>

                  <div className="rev-Card-body">
                    <Row>
                      <Col>
                        <h3 className="CardImage-header">Trusted Provider Network</h3>
                        <h4 className="CardImage-subheader">Desktop Web Application</h4>
                        <p>TPN.health is a digital platform linking behavioral health professionals, treatment facilities, hospitals, and employee wellness programs.</p>
                        <a className="IconLink IconLink--iconRight" href="/tpn">
                          Read Full Case Study <Icon.ArrowRight />
                        </a>
                      </Col>
                    </Row>
                  </div>

                </div>
              </Col>
            </Row>{/* End TPN */}

            <Row flex className="rev-Row--alignStretch">
              <Col medium={6} className="EqualHeightCards">
                <div className="rev-Card CardImage">

                  <div className="CardImage-wrapper">
                    <div className="rev-Card-header">
                      <a href="/bigneon">
                        <img src={bigneon} className="CardImage-img" alt="Big Neon" />
                      </a>
                    </div>

                    <div className="rev-Card-body">
                      <Row>
                        <Col>
                          <h3 className="CardImage-header">Big Neon</h3>
                          <h4 className="CardImage-subheader">Mobile Application, iOS & Android</h4>
                          <p>Big Neon is an event ticketing application that transforms the way music fans buy tickets and experience shows.</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="rev-Card-footer">
                      <a className="IconLink IconLink--iconRight" href="/bigneon">
                        Read Full Case Study <Icon.ArrowRight />
                      </a>
                    </div>
                  </div>

                </div>
              </Col>{/* End Big Neon */}

              <Col medium={6} className="EqualHeightCards">
                <div className="rev-Card CardImage">

                  <div className="CardImage-wrapper">
                    <div className="rev-Card-header">
                      <a href="/lidcore">
                        <img src={lidcore} className="CardImage-img" alt="Lidcore" />
                      </a>  
                    </div>

                    <div className="rev-Card-body">
                      <Row>
                        <Col>
                          <h3 className="CardImage-header">Lidcore, LicenseID &trade;</h3>
                          <h4 className="CardImage-subheader">Content Management System</h4>
                          <p>Lidcore is a technology company that has developed an application to enable the identification of intellectual property to monitor fraudulent activity.</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="rev-Card-footer">
                      <a className="IconLink IconLink--iconRight" href="/lidcore">
                        Read Full Case Study <Icon.ArrowRight />
                      </a>
                    </div>
                  </div>

                </div>
              </Col>{/* End Lidcore */}
            </Row>{/* End Row Wrapper */}

          </Col>
        </Row>
      </section>{/* End Section Grid Wrapper */}

      <Row>
        <Col medium={4} smallCentered>
          <div className="ButtonCentered">
            <a className="rev-Button" href="/portfolio">
              View Full Portfolio & Case Studies
            </a>
          </div>
        </Col>
      </Row>


      <div className="Homepage-bottom">

        <section className="Section-grid">
          <Row>
            <Col xlarge={9} medium={10} smallCentered>

              <h3 className="Text-center">Blog Posts</h3>

              <Row flex className="rev-Row--alignStretch">
                <Col medium={4} className="EqualHeightCards">
                  <div className="rev-Card CardImage CardImage-smaller">

                    <div className="CardImage-wrapper">
                      <div className="rev-Card-header">
                        <a href="https://revelry.co/resources/design/ux-design-react-native/" target="_blank">
                          <img src={blog1} className="CardImage-img" alt="Blog 1" />
                        </a>
                      </div>

                      <div className="rev-Card-body">
                        <Row>
                          <Col>
                            <h4 className="CardImage-subheader">Improving UX Design with React Native Features</h4>
                            <small>Written by Brittany Gay on May 20, 2019</small>
                          </Col>
                        </Row>
                      </div>

                      <div className="rev-Card-footer">
                        <a className="IconLink IconLink--iconRight" href="https://revelry.co/resources/design/ux-design-react-native/" target="_blank">
                          Read Blog Post <Icon.ArrowRight />
                        </a>
                      </div>
                    </div>

                  </div>
                </Col>{/* End Blog 1 */}

                <Col medium={4} className="EqualHeightCards">
                  <div className="rev-Card CardImage CardImage-smaller">

                    <div className="CardImage-wrapper">
                      <div className="rev-Card-header">
                        <a href="https://revelry.co/resources/design/the-only-designer/" target="_blank">
                          <img src={blog2} className="CardImage-img" alt="Blog 2" />
                        </a>
                      </div>

                      <div className="rev-Card-body">
                        <Row>
                          <Col>
                            <h4 className="CardImage-subheader">Managing Large Projects When You’re the Only Designer</h4>
                            <small>Written by Brittany Gay on November 8, 2018</small>
                          </Col>
                        </Row>
                      </div>

                      <div className="rev-Card-footer">
                        <a className="IconLink IconLink--iconRight" href="https://revelry.co/resources/design/the-only-designer/" target="_blank">
                          Read Blog Post <Icon.ArrowRight />
                        </a>
                      </div>
                    </div>

                  </div>
                </Col>{/* End Blog 2 */}

                <Col medium={4} className="EqualHeightCards">
                  <div className="rev-Card CardImage CardImage-smaller">

                    <div className="CardImage-wrapper">
                      <div className="rev-Card-header">
                        <a href="https://revelry.co/resources/design/iphone-x-problem-areas/" target="_blank">
                          <img src={blog3} className="CardImage-img" alt="Blog 3" />
                        </a>
                      </div>

                      <div className="rev-Card-body">
                        <Row>
                          <Col>
                            <h4 className="CardImage-subheader">Building With the iPhone X in Mind: Troubleshooting Common Problem Areas</h4>
                            <small>Written by Brittany Gay on June 25, 2018</small>
                          </Col>
                        </Row>
                      </div>

                      <div className="rev-Card-footer">
                        <a className="IconLink IconLink--iconRight" href="https://revelry.co/resources/design/iphone-x-problem-areas/" target="_blank">
                          Read Blog Post <Icon.ArrowRight />
                        </a>
                      </div>
                    </div>

                  </div>
                </Col>{/* End Blog 3 */}
              </Row>{/* End Row Wrapper */}

            </Col>
          </Row>    
        </section>{/* End Section Grid Wrapper */}

        <Row>
          <Col medium={4} smallCentered>
            <div className="ButtonCentered">
              <a className="rev-Button rev-Button--secondary" href="https://revelry.co/author/brittany/" target="_blank">
                View All Blog Posts
              </a>
            </div>
          </Col>
        </Row>

        <section>
          <Row>
            <Col medium={4} smallCentered>
              <div className="rev-Card CardConnection">

                <h3 className="Text-center">Let's Connect!</h3>
                <p className="Text-center">Feel free to email me to reach out and connect!</p>
                <div className="ButtonCentered">
                  <a className="rev-Button" href="mailto:brittanygay3@gmail.com">
                    Contact Me
                  </a>
                </div>

                <div className="Icon-wrapper">
                  <a className="Icon-social" href="https://www.linkedin.com/in/brittanygay/" target="_blank">
                    <Icon.Linkedin />
                  </a>

                  <a className="Icon-social" href="https://www.instagram.com/brittanygay/" target="_blank">
                    <Icon.Instagram />
                  </a>

                  <a className="Icon-social" href="https://twitter.com/LBGay" target="_blank">
                    <Icon.Twitter />
                  </a>

                  <a className="Icon-social" href="https://dribbble.com/LBGay" target="_blank">
                    <Icon.Dribbble />
                  </a>
                </div>

              </div>
            </Col>
          </Row>
        </section>{/* End Connect Section */}

      </div>{/* End Homepage Bottom Wrapper */}

    </div>
  );
}

export default home;