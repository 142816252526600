import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import tpn_events_desktop_2 from '../images/tpn/tpn-events-desktop-2.jpg';
import bigneon from '../images/bigneon/bn-mockup-1.jpg';
import lidcore from '../images/lidcore/lidcore-mockup-3.jpg';
import tales from '../images/toc/toc-mockup-1.jpg';
import artunity from '../images/artunity/artunity-mockup-4.jpg';
import dtj from '../images/dtj/dtj-mockup-3.jpg';
import cluster from '../images/cluster/cluster-mockup-2.jpg';
import estate from '../images/estateclarity/estate-clarity-mockup-4.jpg';

import '../App.css';

const Portfolio = () => {
  return (
    <div className="Portfolio">
      
      <section className="Section-grid">
        <Row>
          <Col xlarge={9} medium={10} smallCentered>

            <h3 className="Portfolio-header Text-center">Featured Work</h3>

            <Row>
              <Col>
                <div className="rev-Card CardImage CardImageLeft">

                  <div className="rev-Card-header">
                    <a href="/tpn">
                      <img src={tpn_events_desktop_2} className="CardImage-img" alt="Trusted Provider Network" />
                    </a>
                  </div>

                  <div className="rev-Card-body">
                    <Row>
                      <Col>
                        <h3 className="CardImage-header">Trusted Provider Network</h3>
                        <h4 className="CardImage-subheader">Desktop Web Application</h4>
                        <p>TPN.health is a digital platform linking behavioral health professionals, treatment facilities, hospitals, and employee wellness programs.</p>
                        <a className="IconLink IconLink--iconRight" href="/tpn">
                          Read Full Case Study <Icon.ArrowRight />
                        </a>
                      </Col>
                    </Row>
                  </div>

                </div>
              </Col>
            </Row>{/* End TPN */}


            <Row flex className="rev-Row--alignStretch">
              <Col medium={6} className="EqualHeightCards">
                <div className="rev-Card CardImage">

                  <div className="CardImage-wrapper">
                    <div className="rev-Card-header">
                      <a href="/bigneon">
                        <img src={bigneon} className="CardImage-img" alt="Big Neon" />
                      </a>
                    </div>

                    <div className="rev-Card-body">
                      <Row>
                        <Col>
                          <h3 className="CardImage-header">Big Neon</h3>
                          <h4 className="CardImage-subheader">Mobile Application, iOS & Android</h4>
                          <p>Big Neon is an event ticketing application that transforms the way music fans buy tickets and experience shows.</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="rev-Card-footer">
                      <a className="IconLink IconLink--iconRight" href="/bigneon">
                        Read Full Case Study <Icon.ArrowRight />
                      </a>
                    </div>
                  </div>

                </div>
              </Col>{/* End Big Neon */}

              <Col medium={6} className="EqualHeightCards">
                <div className="rev-Card CardImage">

                  <div className="CardImage-wrapper">
                    <div className="rev-Card-header">
                      <a href="/lidcore">
                        <img src={lidcore} className="CardImage-img" alt="Lidcore" />
                      </a>
                    </div>

                    <div className="rev-Card-body">
                      <Row>
                        <Col>
                          <h3 className="CardImage-header">Lidcore, LicenseID &trade;</h3>
                          <h4 className="CardImage-subheader">Content Management System</h4>
                          <p>Lidcore is a technology company that has developed an application to enable the identification of intellectual property to monitor fraudulent activity.</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="rev-Card-footer">
                      <a className="IconLink IconLink--iconRight" href="/lidcore">
                        Read Full Case Study <Icon.ArrowRight />
                      </a>
                    </div>
                  </div>

                </div>
              </Col>{/* End Lidcore */}
            </Row>{/* End Row Wrapper */}


            <Row className="u-marginTopLarge">
              <Col>
                <div className="rev-Card CardImage CardImageLeft">

                  <div className="rev-Card-header">
                    <a href="/toc">
                      <img src={tales} className="CardImage-img" alt="Tales of the Cocktail Foundation" />
                    </a>  
                  </div>

                  <div className="rev-Card-body">
                    <Row>
                      <Col>
                        <h3 className="CardImage-header">Tales of the Cocktail Foundation</h3>
                        <h4 className="CardImage-subheader">Desktop Web Application</h4>
                        <p>Tales of the Cocktail Foundation is a non-profit organization in spirits education, and a platform that helps face issues in the global drinks industry.</p>
                        <a className="IconLink IconLink--iconRight" href="/toc">
                          Read Full Case Study <Icon.ArrowRight />
                        </a>
                      </Col>
                    </Row>
                  </div>

                </div>
              </Col>
            </Row>{/* End Tales */}


            <Row flex className="rev-Row--alignStretch">
              <Col medium={6} className="EqualHeightCards">
                <div className="rev-Card CardImage">

                  <div className="CardImage-wrapper">
                    <div className="rev-Card-header">
                      <a href="/artunity">
                        <img src={artunity} className="CardImage-img" alt="Artunity" />
                      </a>
                    </div>

                    <div className="rev-Card-body">
                      <Row>
                        <Col>
                          <h3 className="CardImage-header">Artunity</h3>
                          <h4 className="CardImage-subheader">Mobile Web Application</h4>
                          <p>Artunity is a mobile web application where artists are discovered and monetize their art by selling Artunities. Artunity allows you an entry point into the buyers' world without any costly capital or marketing.</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="rev-Card-footer">
                      <a className="IconLink IconLink--iconRight" href="/artunity">
                        Read Full Case Study <Icon.ArrowRight />
                      </a>
                    </div>
                  </div>

                </div>
              </Col>{/* End Artunity */}

              <Col medium={6} className="EqualHeightCards">
                <div className="rev-Card CardImage">

                  <div className="CardImage-wrapper">
                    <div className="rev-Card-header">
                      <a href="/dtj">
                        <img src={dtj} className="CardImage-img" alt="Digital Tip Jar" />
                      </a>
                    </div>

                    <div className="rev-Card-body">
                      <Row>
                        <Col>
                          <h3 className="CardImage-header">Digital Tip Jar</h3>
                          <h4 className="CardImage-subheader">Desktop & Mobile Web Application</h4>
                          <p>Digital Tip Jar is an easy to use web app that enables performing artists to accept tips in the form of electronic payment. They provide an easy way for musicians to get the tips that they deserve.</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="rev-Card-footer">
                      <a className="IconLink IconLink--iconRight" href="/dtj">
                        Read Full Case Study <Icon.ArrowRight />
                      </a>
                    </div>
                  </div>

                </div>
              </Col>{/* End DTJ */}
            </Row>{/* End Row Wrapper */}


            <Row flex className="rev-Row--alignStretch u-marginTopLarge">
              <Col medium={6} className="EqualHeightCards">
                <div className="rev-Card CardImage">

                  <div className="CardImage-wrapper">
                    <div className="rev-Card-header">
                      <a href="/cluster">
                        <img src={cluster} className="CardImage-img" alt="Cluster UI" />
                      </a>  
                    </div>

                    <div className="rev-Card-body">
                      <Row>
                        <Col>
                          <h3 className="CardImage-header">Cluster UI</h3>
                          <h4 className="CardImage-subheader">Revelry Services Product</h4>
                          <p>Revelry plans to offer a managed Kubernetes services product, "Revelry Cloud." Revelry Cloud will include high-touch, white glove set-up, monitoring, and support of Kubernetes-powered infrastructure on behalf of its clients.</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="rev-Card-footer">
                      <a className="IconLink IconLink--iconRight" href="/cluster">
                        Read Full Case Study <Icon.ArrowRight />
                      </a>
                    </div>
                  </div>

                </div>
              </Col>{/* End Cluster */}

              <Col medium={6} className="EqualHeightCards">
                <div className="rev-Card CardImage">

                  <div className="CardImage-wrapper">
                    <div className="rev-Card-header">
                      <a href="/estateclarity">
                        <img src={estate} className="CardImage-img" alt="Estate Clarity" />
                      </a>
                    </div>

                    <div className="rev-Card-body">
                      <Row>
                        <Col>
                          <h3 className="CardImage-header">Estate Clarity</h3>
                          <h4 className="CardImage-subheader">Mobile Application, iOS & Android</h4>
                          <p>The Estate Clarity app focuses on the Baby Boomer generation and how they can manage their parents’ estates after they pass away. The idea is to create a simple application to manage deceased relatives’ estates, by appraising and negotiating the value of assets between family members.</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="rev-Card-footer">
                      <a className="IconLink IconLink--iconRight" href="/estateclarity">
                        Read Full Case Study <Icon.ArrowRight />
                      </a>
                    </div>
                  </div>

                </div>
              </Col>{/* End Estate Clarity */}
            </Row>{/* End Row Wrapper */}

          </Col>{/* End Main Col Wrapper */}
        </Row>
      </section>{/* End Main Section-Grid Wrapper */}

    </div>
  );
}

export default Portfolio;