import PropTypes from 'prop-types';
import React from 'react';
import logo from '../images/logo.png';
import { Row, Col } from 'harmonium/lib/grid';
import '../App.css';

const Splash = () => {
  return (
    <div className="Splash-header">
      <Row>
        <Col xlarge={9} medium={10} smallCentered>

          <Row flex>
            <Col small={12} medium={7} smallOrder={2} largeOrder={1}>
              <header>
                <h1>
                  Brittany Gay.<br />
                    UX <strong>Designer</strong>.<br />
                    Front-End <strong>Developer</strong>.
                  </h1>
                <p>
                  Website currently under construction. Please check back soon.
                  </p>
                <a
                  className="rev-Button"
                  href="https://revelry.co/about/brittany/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Blog Posts
                </a>
              </header>
            </Col>

            <Col small={12} medium={5} smallOrder={1} largeOrder={2}>
              <img src={logo} className="Splash-logo" alt="Brittany Gay Design logo" />
            </Col>
          </Row>

        </Col>
      </Row>
    </div>      
  );
}

export default Splash;
