import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import estate1 from '../images/estateclarity/estate-clarity-mockup-1.jpg';
import estate2 from '../images/estateclarity/estate-clarity-mockup-2.jpg';
import estate3 from '../images/estateclarity/estate-clarity-mockup-3.jpg';
import estate4 from '../images/estateclarity/estate-clarity-mockup-4.jpg';
import estate5 from '../images/estateclarity/estate-clarity-mockup-5.jpg';
import estate6 from '../images/estateclarity/estate-clarity-mockup-6.jpg';

import estate_userflow_1 from '../images/estateclarity/estate-clarity-create-userflow.jpg';
import estate_userflow_2 from '../images/estateclarity/estate-clarity-activity-userflow.jpg';
import estate_userflow_3 from '../images/estateclarity/estate-clarity-item-entry-userflow-2.jpg';
import estate_userflow_4 from '../images/estateclarity/estate-clarity-item-entry-userflow.jpg';

import estate_userflow_new_1 from '../images/estateclarity/estate-clarity-login-options-new.jpg';
import estate_userflow_new_2 from '../images/estateclarity/estate-clarity-create-profile-userflow-new.jpg';
import estate_userflow_new_3 from '../images/estateclarity/estate-clarity-activity-userflow-new.jpg';
import estate_userflow_new_4 from '../images/estateclarity/activity-feed-item-entry-userflow-new.jpg';

import bigneon4 from '../images/bigneon/bn-mockup-4.jpg';
import bigneon5 from '../images/bigneon/bn-mockup-1.jpg';

import bigneon_gif_1 from '../images/bigneon/big-neon-gif-1.gif';
import bigneon_gif_2 from '../images/bigneon/big-neon-gif-6.gif';
import bigneon_gif_3 from '../images/bigneon/big-neon-gif-4.gif';
import bigneon_gif_4 from '../images/bigneon/big-neon-gif-5.gif';

import reactnative from '../images/tools/react-native.png';
import nodejs from '../images/tools/nodejs.png';
import xcode from '../images/tools/xcode.jpg';
import atom from '../images/tools/atom.png';
import github from '../images/tools/github.png';
import sketch from '../images/tools/sketch.png';
import invision from '../images/tools/invision.png';
import craft from '../images/tools/craft.jpg';

import '../App.css';

const Estate = () => {
  return (
    <div className="CaseStudy">

      <Row>
        <Col xlarge={7} medium={9} smallCentered>
          <section className="CaseStudy-wrapper">

            <Row>
              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
                <img src={estate2} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <h2 className="CaseStudy-header">Estate Clarity</h2>
                <h4 className="CaseStudy-subheader">Mobile Application, iOS & Android</h4>
                <p>
                  The Estate Clarity app focuses on the Baby Boomer generation and how they can manage their parents’ estates after they pass away. The idea is to create a simple application to manage deceased relatives’ estates, by appraising and negotiating the value of assets between family members.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Role/Responsibility</h4>
                <p>
                  UX Design, Product Design, Front-End Development
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Tools Used</h4>
              </Col>

              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={reactnative} alt="React Native" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={nodejs} alt="Node JS" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={xcode} alt="Xcode" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={atom} alt="Atom" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={github} alt="Github" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={sketch} alt="Sketch" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={invision} alt="Invision" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={craft} alt="Craft" />
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Compatibility Targets</h4>
                <p>
                  <strong>iOS (6 and above)</strong> -  iPhone 6 and above
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Duration/Timeline</h4>
                <p>
                  1-2 months
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Target Audience</h4>
                <p>
                  The Estate Clarity app is created with the Baby Boomer population in mind: People age 65+ with high-income and considerable assets. The user can be the child or executor of a parent's will, or if a Boomer is extra proactive and tech-savvy, they can catalogue their own assets in the app.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Story</h4>
                <p>
                  As Baby Boomers grow old, they are having to deal with managing their parents' estates after they pass away. With an aging population heavily reliant on technology, and families frequently dispersed across the country, there is a growing need for a simple, digital tool to manage deceased relatives' estates. The children of the deceased can use Estate Clarity app to appraise and negotiate the value of assets, then divvy up amongst themselves based on the will.
                  <br /><br />
                  The user (child of the deceased parent) downloads the app, registers, creates a profile, and then creates an estate. They can invite collaborators (siblings, lawyers, etc.) to join the estate (who must first download and register, and create a profile with Estate Clarity). All collaborators in an estate can add items with images and prices, then comment and suggest other prices for the items, which each user will be notified via push notifications. All items will be displayed on the estate dashboard.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Problem Statement/What are we trying to solve?</h4>
                <p>
                  Currently, there is no digital tool for managing estates. Some people use word docs with images, excel sheets, or letters from lawyers. In addition, many siblings have arguments and fights over the value of assets, especially while grieving. The frustration and chaos of dealing with deceased family members' estates can be alleviated through an app that allows users to catalogue and negotiate assets from anywhere.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">UX Process</h4>
                <p>
                  Estate Clarity came to us with a logo, and a few low fidelity wireframes showing their UX thought process. 
                </p>
                <h5>Low Fidelity Wires from Client:</h5>
              </Col>
              <Col>
                <img src={estate_userflow_1} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <img src={estate_userflow_2} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <img src={estate_userflow_3} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <img src={estate_userflow_4} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>

              <Col>
                <p className="u-marginTop">
                  We then did some UX research, by looking at competitors and gathering relevant information on how we can make Estate Clarity stand out from others. Even though there wasn’t much else out there, we had to make sure Estate Clarity hit all of the targets.
                  <br /><br />
                  After that, my job as the UX Designer on the project was to create high fidelity mockups of the user flow with the information that was given, along with filling in any UX holes that we found along the way.
                </p>
                <h5>My High Fidelity Mockups:</h5>
              </Col>
              <Col>
                <img src={estate_userflow_new_1} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <img src={estate_userflow_new_2} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <img src={estate_userflow_new_3} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <img src={estate_userflow_new_4} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>

              <Col>
                <p className="u-marginTop">
                  I then worked with Engineers and our Product Manager to start implementation. I was also the lead Front-End Developer for the React Native app. I started by building out a styleguide with every component that was needed for the app.
                  <br /><br />
                  We worked by weekly sprints, building out each feature at a time. Our time crunch on this project was pretty short, given we had 4 weeks to complete the app. 
                </p>
              </Col>
            </Row>{/* End UX Process Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Problem Scenario</h4>
                <p>
                  Right when we started working on this, the iPhone X had just come out. This was all so new to the team, since the specs were different and there were a lot of UX trends that no longer worked nicely with an iPhone X. This took some adjusting, such as removing the bottom bar and having all options fit into the Hamburger menu. We also had to adjust the TopBar to play nicely with the iPhone X. You can see some of these transitions in each mockup (some are mocked up in an iPhone X and some are mocked up for iPhone 8 and smaller).
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Final Product</h4>
                <p>
                  We were able to complete the deliverables within the four week time span, and the Estate Clarity team took over to get the app submitted to the app store. Estate Clarity still has their internal team working on the app, but Revelry was able to provide a big lift in the duration of our time on the project.
                  <br /><br />
                  <a href="https://apps.apple.com/us/app/estate-clarity/id1325185991" target="_blank">Download the Estate Clarity App!</a>
                </p>
              </Col>

              <Col>
                <img src={estate3} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <img src={estate5} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <img src={estate4} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <img src={estate6} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>
              <Col>
                <img src={estate1} className="CaseStudy-img" alt="Estate Clarity" />
              </Col>


              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
              </Col>
            </Row>{/* End Row Wrapper */}

          </section>
        </Col>
      </Row>

    </div>
  );
}

export default Estate;