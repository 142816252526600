import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'harmonium/lib/grid';
import * as Icon from 'react-feather';

import tpn_events_desktop from '../images/tpn/tpn-events-desktop.jpg';
import tpn3 from '../images/tpn/tpn-mockup-3.jpg';
import tpn5 from '../images/tpn/tpn-mockup-5.jpg';

import tpn_target_audience from '../images/tpn/tpn-target-audience.jpg';
import tpn_mission_statement from '../images/tpn/tpn-mission-statement.jpg';

import tpn_community from '../images/tpn/tpn-community-board.png';
import tpn_homepage from '../images/tpn/tpn-homepage-new.jpg';
import tpn_signup from '../images/tpn/tpn-signup-userflow.png';
import tpn_events_v1 from '../images/tpn/tpn-events-v1.png';
import tpn_events_v2_sketches from '../images/tpn/tpn-events-v2-sketches.jpg';
import tpn_events_v2_wireframes from '../images/tpn/tpn-events-v2-wireframes.png';
import tpn_events from '../images/tpn/tpn-events.png';
import tpn_events_2 from '../images/tpn/tpn-events-v2-final.jpg';
import tpn_events_3 from '../images/tpn/tpn-events-slider.jpg';
import tpn_events_4 from '../images/tpn/tpn-events-on-demand.jpg';
import tpn_events_5 from '../images/tpn/tpn-events-on-demand-2.jpg';
import tpn_user_feedback from '../images/tpn/tpn-user-feedback.jpg';

import tpn_ce_1 from '../images/tpn/tpn-ce-1.jpg';
import tpn_ce_2 from '../images/tpn/tpn-ce-2.jpg';
import tpn_ce_wire_1 from '../images/tpn/tpn-ce-wire-1.png';
import tpn_ce_wire_2 from '../images/tpn/tpn-ce-wire-2.png';
import tpn_ce_wire_3 from '../images/tpn/tpn-ce-wire-3.png';
import tpn_ce_wire_4 from '../images/tpn/tpn-ce-wire-4.png';
import tpn_ce_sketches from '../images/tpn/tpn-ce-sketches.jpg';
import tpn_ce_mockup_1 from '../images/tpn/tpn-ce-mockup-1.jpg';
import tpn_ce_mockup_2 from '../images/tpn/tpn-ce-mockup-2.jpg';

import phoenix from '../images/tools/phoenix.png';
import elixir from '../images/tools/elixir.png';
import vscode from '../images/tools/vs-code.png';
import harmonium from '../images/tools/harmonium.png';
import github from '../images/tools/github.png';
import sketch from '../images/tools/sketch.png';
import invision from '../images/tools/invision.png';
import craft from '../images/tools/craft.jpg';

import '../App.css';

const Tpn = () => {
  return (
    <div className="CaseStudy">

      <Row>
        <Col xlarge={7} medium={9} smallCentered>
          <section className="CaseStudy-wrapper">

            <Row>
              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
                <img src={tpn_events_desktop} className="CaseStudy-img" alt="TPN Events Desktop" />
              </Col>
              <Col>
                <h2 className="CaseStudy-header">Trusted Provider Network</h2>
                <h4 className="CaseStudy-subheader">CE Tracker and Education Library</h4>
                <p>
                  Trusted Provider Network, also known as <strong>TPN.health</strong>, is a digital platform that links behavioral health professionals, treatment facilities, hospitals, and employee wellness programs. They are a matching tool for connecting mental health professionals and the general public with a clinically appropriate, vetted network of clinicians and providers.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Role & Timeline</h4>
                <p>
                  Lead Designer, UX/UI Design, Product Design, Front-End Development, UX Research
                  <br /> <br />
                  2 ½ years+ (currently ongoing)
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Tools Used</h4>
              </Col> 

              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={phoenix} alt="Phoenix" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={elixir} alt="Elixir" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={vscode} alt="Vscode" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={harmonium} alt="Harmonium" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={github} alt="Github" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={sketch} alt="Sketch" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={invision} alt="Invision" />
              </Col>
              <Col medium={3} small={4} className="CaseStudy-tools">
                <img src={craft} alt="Craft" />
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Target Audience</h4>
                <img src={tpn_target_audience} className="CaseStudy-img NoBorder Smaller" alt="TPN Target Audience" />
                <p>
                  TPN.health is building this platform for Clinicians (psychiatrists, psychologists, social workers), and Organizations or Treatment Centers (Hospital Networks or groups).
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Problem Statement</h4>
                <p>
                  There are an abundance of "bad players" in the market, where the main objective is to fill beds at facilities and the patient care becomes a secondary concern. TPN.health is trying to create a reliable network for clinicians and providers to connect with each other and make patient care the primary concern in the referral process.
                </p>
                <p>
                  TPN.health offers our users a local community with a national reach to providers who meet the highest code of clinical services, ethics, and professional standards. Whenever a new user signs up for TPN.health, our clinical team verifies their license with the relevant state board, to ensure that every professional on our site meets the criteria to treat clients in their state.
                </p>
                <p>
                  TPN.health's mission is to provide <strong>trust and integrity while improving behavioral health outcomes</strong> for clinicians.
                </p>
                <img src={tpn_mission_statement} className="CaseStudy-img NoBorder" alt="TPN Mission Statement" />

              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Case Studies</h4>
                <p>
                  After working with the TPN.health team for nearly 3 years, I've worked on multiple features, userflows, and enhancements to the application. Below I will showcase two recent case studies: the Education Library and the CE Tracker for clinicians.
                </p>
              </Col>  
            </Row>  

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Case Study 1: Education Library</h4>
                <p>
                  The continuing education library on TPN.health is approved for social workers, addiction counselors, counselors, psychologists, and more. It’s a free place for live networking events and saved On Demand events.
                </p>

                <h5>User Problem</h5>
                <p>
                  Clinicians need a way to enhance their clinical practices, mainly by obtaining a certain amount CE credit hours per year. Often, it’s hard for them to find free continuing education that can be used as CE credits for their practices.
                </p>

                <h5>Business Goals</h5>
                <p>
                  TPN.health’s main goal is to not let location, time or money get in the way of clinician’s professional development. CE Credits are a very important part of the industry, and our Education Library has CEs approved for social workers, counselors, addiction counselors, as well as psychologists.
                </p>

                <h5>Round Table Interviews: Key Findings</h5>
                <ul class="CaseStudy-list">
                  <li>
                    Helping the user know what education is available would be helpful to clinicians
                  </li>
                  <li>
                    Regional Events could be relevant to specific populations and bring awareness to the community; a great way to connect clinicians
                  </li>
                  <li>
                    Everyone has to do regular Ethics training, which clinicians find annoying
                  </li>
                  <li>
                    Many CEs are focus on therapists, but tend to neglect other fields. How can TPN.health fix this?
                  </li>
                </ul>  

                <h5>User Feedback From Version 1</h5>
                <p>
                  Upon starting our Education Library, I worked to improve our first version, which was done about a year prior and outdated in terms of what the user needs. 
                </p>  
                <p>
                  <img src={tpn_events_v1} className="CaseStudy-img Smaller" alt="TPN Events V1" />
                  <small class="CaseStudy-disclaimer">Outdated version 1 of the Education Library</small>
                </p>

                <h5>User Feedback after V1</h5>
                <ul class="CaseStudy-list">
                  <li>
                    The Search functionality is taking up too much real-estate
                  </li>
                  <li>
                    Tabs are hard to find
                  </li>
                  <li>
                    Titles are too long and author name needs to live on it's own line
                  </li>
                </ul>

                <h5>Starting on V2: Sketches and Wireframes</h5>
                <p>  
                  I started with some UX thinking based on our initial user interviews, conducted more user research, and met with the marketing and support team to discuss further ideation. From there, I then started on some sketches, and moved into low fidelity wireframes.
                </p>
                <p>
                  <img src={tpn_events_v2_sketches} className="CaseStudy-img NoBorder" alt="TPN Events V2 Sketches" />
                </p>
                <p>
                  <img src={tpn_events_v2_wireframes} className="CaseStudy-img Smaller" alt="TPN Events V2 Wireframes" />
                  <small class="CaseStudy-disclaimer">Education Library V2 Sketches and Wireframes</small>
                </p>

                <h5>Moving into High Fidelity Mockups</h5>
                <p>
                  One main area of focus was to show each Event type in it’s own section, with an easy way to identify each and allow the user to scroll through each section. I had a lot of key points to show on the Events Cards while keeping things clean and easy to understand. I made the Event Type stand out as a Tag at the top right of the Card, overlapping the speaker image.
                </p>
                <p>
                  Since we have multiple sections for each Event type, I broke them out and presented the idea of using a sliding carousel for each, showing three at a time on desktop and tablet, and 1 at a time on mobile.
                </p>
                <p>
                  <img src={tpn_events_3} className="CaseStudy-img" alt="TPN Events Slider" />
                </p>
                <h5>On Demand Events Section</h5>
                <br />
                <p>
                  One challenge was to break out the On Demand Events into a separate page, and deciding the UX on how to best navigate to that section while keeping the existing or similar UI while also setting it apart.
                </p>
                <p>
                  To do so, I went back to the drawing board and came up with ideas on how to make the UX as fluid as possible. I added a new “On Demand” link in the TopBar Dropdown, and I also added Cards from the main Events page to easily navigate to On Demand (and vise versa on the On Demand page if the user wished to navigate back to the main Events page). I also added an On Demand section on the main Events page so the user can get a sneak peak and navigate to that page with ease.
                </p>
                <p>
                  <img src={tpn_events_4} className="CaseStudy-img" alt="TPN Events On Demand" />
                  <img src={tpn_events_5} className="CaseStudy-img" alt="TPN Events On Demand 2" />
                  <small class="CaseStudy-disclaimer">On Demand Section</small>
                </p>

                <h5>Final Product</h5>
                <p>
                  <img src={tpn_events_2} className="CaseStudy-img" alt="TPN Events New" />
                  <img src={tpn_events} className="CaseStudy-img NoBorder" alt="TPN Events New" />
                </p>

                <h5>User Testing Feedback</h5>
                <p>
                  Once the new Education Library was launched, we conducted some user tests with clinicians who are familiar with the app, as well as clinicians who are new to the app. Here are some of our key findings:
                  <img src={tpn_user_feedback} className="CaseStudy-img" alt="TPN User Feedback" />
                </p>
                <p>
                  We are currently working on more updates to the Education Library based on user research and findings.
                </p>
              </Col>
            </Row>{/* End Row Wrapper */}

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Case Study 2: CE Tracker</h4>
                <p>
                  TPN.health already offers free CE's through our Education Library, so now we can provide a CE Tracker as a great benefit to our Clinicians.
                </p>
                <img src={tpn_ce_2} className="CaseStudy-img NoBorder" alt="TPN CE Tracker Mockup" />

                <h5>User Problem</h5>
                <p>
                  Many Clinicians find it difficult to stay organized with keeping their hours and licenses up to date. This negatively affects not only the clinicians but the organizations that they work for, and the insurances that list them as care providers.
                </p>
                <p>
                  In order to keep their licenses in good standing, Clinicians must complete a set number of continuing education hours each year and within those hours certain required topics or subject matters. Each state has different criteria (and for each license) to complete Continuing Education requirements. These requirements include differing hours needed, renewal periods/times, subjects/topics, and accrediting bodies.
                </p>

                <h5>Proposed Solution</h5>
                <p>
                  We can give Clinicians a simple way to track what CE's they have, what CE's they need, and when they need to complete them for their specific licenses. This will include easy-to-read visualizations of their CE data, reminders for key dates. and suggested courses for CE completion. We can then allow this data to be shared with the Organizations they belong to, so they know their clinical team/care providers are in good standing with their license, and they can assist in the push for the clinician to remain in good standing.
                </p>
                <p>
                  There are other businesses that offer these services. The difference between them and TPN.health is that they are paid services, the courses cost money and the information is only visible to the individual clinicians.
                </p>

                <h5>Researching Competitors</h5>
                <p>
                  While I worked on this feature, I synced with our Support team daily to get all of the information needed, as well as doing research and gathering some key findings on competitors. One of our biggest competitors is the NASW CE Tracker.
                </p>
                <img src={tpn_ce_1} className="CaseStudy-img NoBorder" alt="TPN CE Image" />
                <p>
                  During this phase of the project, we also conducted some initial user research to find out what clinicians really need, as well as what they currently use to track their CE hours. From our findings, <strong>the majority of them use Excel spreadsheets or paper and pen</strong>. Most of our users don't want to pay to keep track of their CEs, so this finding is very beneficial in our case.
                </p>

                <h5>Starting on Sketches and Wireframes</h5>
                <img src={tpn_ce_sketches} className="CaseStudy-img NoBorder" alt="TPN CE Sketches" />
                <p><small class="CaseStudy-disclaimer">CE Tracker Initial Sketches</small></p>

                <p>
                  First, I started with some UX thinking with the information I had, and I paired with the Support team after compiling questions. Then I got started on some basic layout sketches and laying down thoughts on what components may work best to present the data needed.
                </p>

                <Row>
                  <Col medium={6}>
                    <img src={tpn_ce_wire_1} className="CaseStudy-img" alt="TPN CE Wire" />
                  </Col>  
                  <Col medium={6}>
                    <img src={tpn_ce_wire_3} className="CaseStudy-img" alt="TPN CE Wire" />
                  </Col> 
                </Row>
                <Row>  
                  <Col medium={6}> 
                    <img src={tpn_ce_wire_4} className="CaseStudy-img" alt="TPN CE Wire" />
                  </Col>
                  <Col medium={6}>  
                    <img src={tpn_ce_wire_2} className="CaseStudy-img" alt="TPN CE Wire" />
                  </Col>
                  <p><small class="CaseStudy-disclaimer">CE Tracker Wireframes</small></p>
                </Row>

                <p>
                  Once I got my thoughts down on paper, I moved into basic wireframes in Axure, then presented my wireframes to our Head of Product to make sure I was on the right track.  
                </p>

                <h5>High Fidelity Prototypes</h5>
                <p>
                  Once my wireframes got approved, I moved straight into high fidelity prototypes. We went through multiple rounds of revisions, numerous calls with the stakeholders, and lots of feedback from the team until we reached our final mockups. 
                </p>
                <p>
                  The main focus for the CE Dashboard is for the user to have a quick overview of their Licensures and visuals depicting how many CEs they have earned, and how many they have left to go. They also have the ability to upload their own CEs, outside of the TPN.health platform.
                  <img src={tpn_ce_mockup_1} className="CaseStudy-img" alt="TPN CE Dashboard" />
                  <p><small class="CaseStudy-disclaimer">CE Tracker Dashboard</small></p>
                </p>
                <p>
                  The user is able to drill into each Licensure, and view a breakdown of their Categories, and how many CEs they have for each, as well as how many they still need to earn. The License Details page also has a table showing a list of all of their CEs, as well as the ability to upload. 
                  <img src={tpn_ce_mockup_2} className="CaseStudy-img" alt="TPN CE License Details" />
                  <p><small class="CaseStudy-disclaimer">CE Tracker - License Details</small></p>
                </p>
                <p>
                  It took a few iterations to get the data presentable in the easiest to understand manner. I broke out new Badges to show remaining CEs, versus the fraction of what is completed and needed for each category. There is also a link to a Modal on each category which shows suggested events to prompt the user to finish those sections.
                </p>
                <p>
                  Both of these userflows have about 10-15 slides per prototype, but the above show the main Dashboards as well as my proposed solutions to build out a clean, easy to understand UI.
                </p>

                <h5>User Testing</h5>
                <p>
                  We are conducting user interviews and showing clinicians each high fidelity prototype to get initial feedback on what will be most helpful for our users. The CE Tracker feature is currently being implemented, with a completion date of early 2022. Once we have the feature on production, we plan on conducting qualitative usability testing, as well as accessibility evaluations and general feedback from clinicians and finding any pain points they may have.
                </p>
              </Col>
            </Row>    

            <Row>
              <Col>
                <h4 className="CaseStudy-sectionHeader">Final Product</h4>
                <p>
                  The TPN.health platform is <a href="https://tpn.health/" target="_blank">available to the public now.</a>
                  <br /><br />
                  This is an ongoing project/application, so as we finish new features and deploy our updates, they are then presented on the platform for users. This project is currently ongoing, with no current end date. 
                </p>
              </Col>

              <Col>
                <p>
                  Here are some of our finalized branding updates:
                </p>
                <p>
                  <img src={tpn_homepage} className="CaseStudy-img" alt="TPN New Homepage" />
                </p>
                <p>
                  <img src={tpn_community} className="CaseStudy-img NoBorder" alt="TPN New Community Board" />
                  <img src={tpn_signup} className="CaseStudy-img NoBorder" alt="TPN New Signup Userflow" />
                </p>
              </Col>

              <Col>
                <img src={tpn5} className="CaseStudy-img" alt="TPN" />
              </Col>
              <Col>
                <img src={tpn3} className="CaseStudy-img" alt="TPN Dashboard" />
              </Col>
              <Col>
                <a className="IconLink IconLink--iconLeft" href="/portfolio">
                  <Icon.ArrowLeft /> Back to Portfolio
                </a>
              </Col>
            </Row>{/* End Row Wrapper */}
  
          </section>
        </Col>
      </Row>

    </div>
  );
}

export default Tpn;